import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import "./News.scss"
import { Link } from "@StarberryUtils";
import axios from 'axios';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import moment from "moment"
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { BLOGS_PAGE_URL } from "@Components/common/site/constants";
const RELATED_NEWS = gql`
query GetNews($Title: String!){
    blogs(where:{Title_ne:$Title}) {
        Title
        URL
        id
        Date
        Embed_Video_URL
        Tail_Image {
          url
          alternativeText
        }
    }
}
`;
const News = (props) => {
    const { loading: relatednewsloading, error: relatednewserror, data: relatednews } = useQuery(RELATED_NEWS, {
        variables: { Title: props.CurrentNews }
    });
    const [videoId, setVideoId] = useState('');
    const [showVideo, setShowVideo] = useState(false);
    const [videoindex, setVideoindex] = useState(null);
    const playVideo = (id, Embed_Video_URL) => {
        var videoid = getVideoId(Embed_Video_URL);
        setShowVideo(true)
        setVideoindex(id)
        setVideoId(videoid.id)
    }
    const closePlay = () => {
        setShowVideo(false)
        setVideoId('')
        setVideoindex(null)
    }
    const trackerVideo = (event) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': event,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
        });
    }
    if (relatednewsloading) return <p>Loading ...</p>;
    return (relatednews && relatednews?.blogs?.length > 0 ?
        <section className="news-section similar">
            <Container>
                <Row>
                    <Col lg={12}>
                        <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                            <div className="block-title">

                                <h2>Other Articles that may interest you...</h2>
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <div className="news-box-wrap">
                    <ScrollAnimation animateOnce={true} animateIn='fadeIn'>
                        <Row className="flex-lg-nowrap">
                            {relatednews?.blogs?.slice(0, 3).map((item, index) => (
                                <Col lg={4} md={6} key={index}>
                                    <div className="news-box">
                                        {showVideo && videoindex === index && <div onClick={(e) => { closePlay(e) }} className="close-iframe-btn"></div>}
                                        <div className="properties-wrapper img-zoom">
                                            {showVideo && videoindex === index &&
                                                <YouTube
                                                    video={videoId}
                                                    autoplay
                                                    showRelatedVideos={false}
                                                    showInfo={false}
                                                    annotations={false}
                                                    onEnd={e => { closePlay(e) }}
                                                    modestBranding={1}
                                                    onPlaying={trackerVideo(item?.Title)}

                                                />
                                            }
                                            <Link to={BLOGS_PAGE_URL.alias + '/' + item.URL}>
                                                <picture>
                                                    <source media="(min-width:1200px)" srcSet={item.Tail_Image?.url} />
                                                    <source media="(min-width:768px)" srcSet={item.Tail_Image?.url} />
                                                    <img src={item.Tail_Image?.url} alt={item.Tail_Image?.alternativeText} />
                                                </picture>
                                            </Link>
                                            {item.Embed_Video_URL&&
                                            <div className="news-btn">
                                                <strong
                                                    className="video-btn "
                                                    onClick={(e) => {
                                                        playVideo(index, item.Embed_Video_URL);
                                                    }}
                                                >
                                                    <i className="icon-video "></i>
                                                </strong>
                                            </div>
}
                                        </div>
                                        <div className="newsletter-info">
                                            <div className="time-info">{moment(item?.Date).format('DD MMMM YYYY')}</div>
                                            <h3>
                                                {" "}
                                                <Link to={BLOGS_PAGE_URL.alias + '/' + item.URL}>{item.Title}</Link>{" "}
                                            </h3>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </ScrollAnimation>

                </div>

            </Container>

        </section>
        : <></>)
}
export default News